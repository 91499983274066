import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ROUTES } from "./constants/routes";

// import Loader from 'src/components/Loaders/Spinner';

// Public Routes
const Home = lazy( () => import( "../src/pages/home" ) );
const Layout = lazy( () => import( "../src/pages/layout" ) );

const MainRoutes = () => {
  return (
    // <Suspense fallback={<Loader />}>
    <Suspense>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
