import { useEffect } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import i18next from './i18n/index';
import MainRoutes from "./router";
import './App.css';

const App = () => {
  const { i18n } = useTranslation(["home"]);
  const isRTL = i18n.dir() === 'rtl';
  useEffect(() => {
    if (isRTL) {
      document.documentElement.style.setProperty('--font-default', 'Noto Nastaliq Urdu, serif');
    } else {
      document.documentElement.style.setProperty('--font-default', 'Libre Baskerville, serif');
    }
    document.documentElement.dir = isRTL ? 'rtl' : 'ltr';
  }, [isRTL]);

  return (
    <I18nextProvider i18n={i18next}>
      <Router>
        <MainRoutes />
      </Router>
    </I18nextProvider>
  );
};

export default App;
